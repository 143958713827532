var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cultureRM home" }, [
    _c("section", { staticClass: "culture-section1" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/reviewmind/circle.png"),
          alt: "image"
        }
      }),
      _c("h2", [_vm._v(_vm._s(_vm.$t("culture_main_title")))]),
      _c("div", { staticClass: "cultureCode" }, [
        _c("div", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/cultureCode1.png"),
              alt: "image"
            }
          }),
          _c("div", { staticClass: "textWrap" }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("culture_title_1")))]),
            _c("p", [
              _vm._v(" " + _vm._s(_vm.$t("culture_title_context_1")) + " ")
            ])
          ])
        ]),
        _c("div", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/cultureCode2.png"),
              alt: "image"
            }
          }),
          _c("div", { staticClass: "textWrap" }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("culture_title_2")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("culture_title_context_2")))])
          ])
        ]),
        _c("div", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/cultureCode3.png"),
              alt: "image"
            }
          }),
          _c("div", { staticClass: "textWrap" }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("culture_title_3")))]),
            _c("p", [
              _vm._v(" " + _vm._s(_vm.$t("culture_title_context_3")) + " ")
            ])
          ])
        ]),
        _c("div", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/cultureCode4.png"),
              alt: "image"
            }
          }),
          _c("div", { staticClass: "textWrap" }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("culture_title_4")))]),
            _c("p", [
              _vm._v(" " + _vm._s(_vm.$t("culture_title_context_4")) + " ")
            ])
          ])
        ]),
        _c("div", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/cultureCode5.png"),
              alt: "image"
            }
          }),
          _c("div", { staticClass: "textWrap" }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("culture_title_5")))]),
            _c("p", [
              _vm._v(" " + _vm._s(_vm.$t("culture_title_context_5")) + " ")
            ])
          ])
        ]),
        _c("div", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/cultureCode6.png"),
              alt: "image"
            }
          }),
          _c("div", { staticClass: "textWrap" }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("culture_title_6")))]),
            _c("p", [
              _vm._v(" " + _vm._s(_vm.$t("culture_title_context_6")) + " ")
            ])
          ])
        ])
      ])
    ]),
    _c("section", { staticClass: "culture-section2" }, [
      _c("h2", [
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/circle.png"),
            alt: "image"
          }
        }),
        _vm._v(" " + _vm._s(_vm.$t("member")) + " ")
      ]),
      _c("ul", [
        _c("li", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/profile1.jpg"),
              alt: "image"
            }
          }),
          _c("h3", [_vm._v("Sunny Kim")]),
          _c("p", [_vm._v("CEO / AI Developer")]),
          _c("p", { staticClass: "profile_info" }, [
            _vm._v(" San Diego State University "),
            _c("br"),
            _vm._v("(Master in Information Systems) "),
            _c("br"),
            _vm._v(_vm._s(_vm.$t("member_context_1_1")) + " "),
            _c("br"),
            _vm._v(_vm._s(_vm.$t("member_context_1_2")) + " ")
          ]),
          _vm._m(0)
        ]),
        _c("li", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/profile2.jpg"),
              alt: "image"
            }
          }),
          _c("h3", [_vm._v("Wonhyuk Jang")]),
          _c("p", [_vm._v("AI Developer")]),
          _c("p", { staticClass: "profile_info" }, [
            _vm._v(" University of Southern California "),
            _c("br"),
            _vm._v("(Master in Computer Science) "),
            _c("br"),
            _vm._v(_vm._s(_vm.$t("member_context_2_1"))),
            _c("br"),
            _vm._v(_vm._s(_vm.$t("member_context_2_2")) + " ")
          ]),
          _vm._m(1)
        ]),
        _c("li", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/profile3.jpg"),
              alt: "image"
            }
          }),
          _c("h3", [_vm._v("Yeonsu Choi")]),
          _c("p", [_vm._v("Digital Marketer")]),
          _c("p", { staticClass: "profile_info" }, [
            _vm._v(" " + _vm._s(_vm.$t("member_context_3_university")) + " "),
            _c("br"),
            _vm._v(_vm._s(_vm.$t("member_context_3_major")) + " "),
            _c("br"),
            _vm._v(_vm._s(_vm.$t("member_context_3_1")) + " "),
            _c("br"),
            _vm._v(_vm._s(_vm.$t("member_context_3_2")) + " ")
          ]),
          _vm._m(2)
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "https://www.linkedin.com/in/sunny-kim-analytics/" } },
      [
        _c("button", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/linkedin.png"),
              alt: "image"
            }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "https://www.linkedin.com/in/wonhyuk-jang-44a941b4/" } },
      [
        _c("button", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/linkedin.png"),
              alt: "image"
            }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "https://www.linkedin.com/in/yeonsu-choi-947597210" } },
      [
        _c("button", [
          _c("img", {
            attrs: {
              src: require("@/assets/images/reviewmind/linkedin.png"),
              alt: "image"
            }
          })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }